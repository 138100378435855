import { cn } from '@/core/ui/utils';
import { CSSProperties, ReactNode } from 'react';

export const RoundedTile = ({
  style,
  children,
  isSelected,
  onSelect,
  image,
}: {
  style?: CSSProperties;
  children?: ReactNode;
  isSelected?: boolean;
  onSelect?: () => void;
  image?: ReactNode;
}) => (
  <li
    className={cn(
      'relative',
      'overflow-hidden',
      'w-[106px]',
      'h-[124px]',
      'rounded-xl',
      isSelected ? 'mt-4' : 'mt-0',
      'transition-all',
      'duration-200'
    )}
    style={style}
  >
    <button
      className="flex h-full p-3 text-left text-sm font-medium text-gray-750"
      onClick={onSelect}
      type="button"
    >
      <div
        style={{
          width: IMAGE_WIDTH,
          height: IMAGE_HEIGHT,
          position: 'absolute',
          bottom: 0,
          right: 0,
        }}
      >
        {image}
      </div>
      {children}
    </button>
  </li>
);

export const IMAGE_WIDTH = 106;
export const IMAGE_HEIGHT = 72;
