import { cn } from '@/core/ui/utils';
import { Media } from '@lib/theme/components/Media';

import { BannersCarousel } from './components/BannersCarousel';
import { Banner } from './types/types';

type BannersProps = {
  desktopBanners: Banner[];
  mobileBanners: Banner[];
  desktopBannerHeight: number;
  desktopBannerWidth: number;
  mobileBannerHeight: number;
  mobileBannerWidth: number;
  className?: string;
};

export const Banners = ({
  desktopBanners,
  mobileBanners,
  desktopBannerHeight,
  desktopBannerWidth,
  mobileBannerHeight,
  mobileBannerWidth,
  className,
}: BannersProps) => (
  <>
    <Media greaterThanOrEqual="sm">
      <div className={cn('px-6', 'xl:px-0', className)}>
        <BannersCarousel
          banners={desktopBanners}
          naturalSlideHeight={desktopBannerHeight}
          naturalSlideWidth={desktopBannerWidth}
        />
      </div>
    </Media>
    <Media lessThan="sm">
      <BannersCarousel
        naturalSlideHeight={mobileBannerHeight}
        naturalSlideWidth={mobileBannerWidth}
        banners={mobileBanners}
      />
    </Media>
  </>
);
