import { useBanners } from '@/core/hooks/useBanners';
import { Banners } from '@/core/ui/components/Banners/Banners';

import { HomepageBannersSection } from '../../queries/types';

export const BannersSection = ({
  section,
}: {
  section: HomepageBannersSection;
}) => {
  const {
    desktopBannerHeight,
    desktopBannerWidth,
    desktopBanners,
    mobileBannerHeight,
    mobileBannerWidth,
    mobileBanners,
  } = useBanners({ banners: section.banners });

  return (
    <Banners
      desktopBannerHeight={desktopBannerHeight}
      desktopBannerWidth={desktopBannerWidth}
      desktopBanners={desktopBanners}
      mobileBannerHeight={mobileBannerHeight}
      mobileBannerWidth={mobileBannerWidth}
      mobileBanners={mobileBanners}
    />
  );
};
