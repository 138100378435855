import NextLink from 'next/link';

import { APP_ROUTES } from '@/config/routing';
import { Img } from '@lib/img/components/Img';
import { createImage } from '@lib/sanity/create-image';
import {
  IMAGE_HEIGHT,
  IMAGE_WIDTH,
  RoundedTile,
} from '@lib/theme/components/RoundedTile';

export const PromotionTile = ({
  mobileImageUrl,
  title,
  backgroundColor,
  promotionKey,
}: {
  title: string;
  backgroundColor: string;
  mobileImageUrl: string;
  promotionKey: string;
}) => {
  const image = createImage({
    imageUrl: mobileImageUrl,
    imageWidth: IMAGE_WIDTH,
    imageHeight: IMAGE_HEIGHT,
    alt: title,
  });

  return (
    <NextLink href={APP_ROUTES.promotions(promotionKey)} shallow>
      <RoundedTile
        image={
          image && <Img {...image} imageFit="contain" imagePosition="right" />
        }
        style={{
          backgroundColor,
        }}
      >
        {title}
      </RoundedTile>
    </NextLink>
  );
};
