import { MouseEvent } from 'react';

import { cn } from '@/core/ui/utils';
import { IconOnlyButton } from '@lib/theme/components/IconOnlyButton';

type BannerArrowProps = {
  title: string;
  type?: 'left' | 'right';
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  isDisabled?: boolean;
};

export const BannerArrow = ({
  title,
  type = 'left',
  onMouseLeave,
  onMouseEnter,
  onClick,
  isDisabled,
}: BannerArrowProps) => (
  <div
    className={cn(
      'absolute',
      'top-1/2',
      type === 'left'
        ? ['-scale-100', '-translate-x-1/2', '-translate-y-1/2', 'left-0']
        : ['translate-x-1/2', 'right-0', '-translate-y-1/2']
    )}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <IconOnlyButton
      isDisabled={isDisabled}
      round="full"
      color="tertiary/neutral"
      icon="ChevronRightThinIcon"
      onClick={onClick}
      size="lg"
      title={title}
    />
  </div>
);
