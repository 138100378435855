import Link from 'next/link';

import { cn } from '@/core/ui/utils';
import { sendBannerSelectEvent } from '@/modules/analytics/eventHandlers/sendBannerSelectEvent';
import { FC } from '@/types/common';
import { ImgProps } from '@lib/img/components/Img';

import { BannersCarouselSlideImage } from './BannersCarouselSlideImage';

export const BannersCarouselSlide: FC<BannersCarouselSlideProps> = ({
  type,
  link,
  title,
  src,
  srcSetObj,
  index,
  naturalSlideHeight,
  naturalSlideWidth,
  placeholder,
}) => {
  const image = (
    <BannersCarouselSlideImage
      title={title}
      src={src}
      srcSetObj={srcSetObj}
      placeholderSrc={placeholder ?? ''}
      naturalSlideHeight={naturalSlideHeight}
      naturalSlideWidth={naturalSlideWidth}
      isVisible
    />
  );

  const handleClick = () => {
    sendBannerSelectEvent({
      position: index + 1,
      type,
      link,
      title,
      backgroundImage: src,
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <div
      tabIndex={0}
      role="button"
      key={title}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      className={cn(
        'keen-slider__slide',
        'min-w-full',
        'rounded-lg',
        'sm:rounded-2xl'
      )}
    >
      {link ? <Link href={link}>{image}</Link> : image}
    </div>
  );
};

export type BannersCarouselSlideProps = {
  type: string | undefined;
  link: string | null | undefined;
  title: string;
  src: string;
  srcSetObj: ImgProps['srcSetObj'];
  placeholder: string | undefined;
  index: number;
  naturalSlideWidth: number;
  naturalSlideHeight: number;
};
