import {
  CategoryLandingBanner,
  CategoryLandingBanners,
  CategoryLandingUspBannersWithoutCategoryAndPromotionBanner,
} from '@/modules/categoryLanding/queries/types';
import {
  HomepageBanner,
  HomepageBanners,
  HomepageUspBannerWithoutCategoryAndPromotionBanner,
} from '@/modules/home/queries/types';
import { publicEnv } from '@lib/env/public-env-vars';

export const mapBanners = (banners: HomepageBanners | CategoryLandingBanners) =>
  banners.filter(({ id }) => id).map(addPromotionAndCategoryLinkBanner);

const generateCategoryLink = (
  l0Category: string | undefined,
  l1Category: string | undefined,
  key: string
): string => {
  if (l0Category && l1Category) {
    return `${l0Category}/${l1Category}/${key}`;
  }
  if (l0Category) {
    return `${l0Category}/${key}`;
  }
  return `${key}`;
};

const addPromotionAndCategoryLinkBanner = (
  banner:
    | CategoryLandingUspBannersWithoutCategoryAndPromotionBanner
    | CategoryLandingBanner
    | HomepageBanner
) => {
  if (banner.__typename === 'WebPromotionBanner' && 'promotion' in banner) {
    const {
      promotion: { key, ancestor },
    } = banner;
    const link = ancestor?.key ? `${ancestor.key}/${key}` : key;

    return {
      ...banner,
      link: `${publicEnv.NEXT_PUBLIC_WEBSITE_URL}/promotions/${link}`,
    };
  }

  if (banner.__typename === 'WebCategoryBanner' && 'category' in banner) {
    const { ancestors, key } = banner.category || {};
    const [l0Category, l1Category] = ancestors;
    const link = generateCategoryLink(l0Category?.key, l1Category?.key, key);

    return {
      ...banner,
      link: `${publicEnv.NEXT_PUBLIC_WEBSITE_URL}/categories/${link}`,
    };
  }
  return addLinkPropertyToBanner(banner);
};

export const addLinkPropertyToBanner = (
  banner:
    | HomepageUspBannerWithoutCategoryAndPromotionBanner
    | HomepageBanner
    | CategoryLandingUspBannersWithoutCategoryAndPromotionBanner
    | CategoryLandingBanner
) => {
  if ('product' in banner) {
    return {
      ...banner,
      link: `${publicEnv.NEXT_PUBLIC_WEBSITE_URL}/products/${banner.product.slug}`,
    };
  }
  return banner;
};
