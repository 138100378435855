import { pushGtmEvent } from '@/core/services/pushGtmEvent';

type SendBannerSelectEventArgs = {
  position: number;
  type: string | undefined;
  backgroundImage: string;
  link?: string | null;
  title?: string;
};
export const sendBannerSelectEvent = ({
  backgroundImage,
  title,
  position,
  link,
  type,
}: SendBannerSelectEventArgs) =>
  pushGtmEvent({
    event: 'banner_select',
    lite_position: position,
    lite_type: mapTypenameToType(type),
    lite_link: link ?? null,
    lite_background_image: backgroundImage,
    lite_title: title,
  });

const mapTypenameToType = (typename: string | undefined) => {
  const typeMap: { [key: string]: string } = {
    WebLinkBanner: 'link',
    WebStaticBanner: 'static',
    WebCategoryBanner: 'category',
    WebProductBanner: 'product',
    WebPromotionBanner: 'promotion',
    WebCategoryUspBanner: 'category-usp',
    WebLinkUspBanner: 'link-usp',
    WebProductUspBanner: 'product-usp',
    WebStaticUspBanner: 'static-usp',
  };

  return typename ? typeMap[typename] || typename : typename;
};
