import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';

import { cn } from '@/core/ui/utils';
import { AccessibleSvg } from '@lib/theme/components/AccessibleSvg';
import { buttonIconComponents } from '@lib/theme/config/buttonIcons';

type PropsType = {
  title: ReactNode;
  sectionUrl?: string | null;
  className?: string;
};

export const ProductsCarouselTitle = ({
  sectionUrl,
  title,
  className,
}: PropsType) => {
  const { t } = useTranslation('common');

  return (
    <div className="flex items-center justify-between">
      <h2
        className={cn(
          'text-title-lg font-medium md:text-heading-large',
          className
        )}
      >
        {title}
      </h2>
      {sectionUrl && (
        <div className="md:hidden">
          <Link
            href={sectionUrl}
            className="ml-6 flex size-8 items-center justify-center rounded-full bg-gray-100"
          >
            <AccessibleSvg
              SvgComponent={buttonIconComponents.ArrowRightIcon}
              title={t('Next slide')}
              className="size-4 text-gray-900"
            />
          </Link>
        </div>
      )}
    </div>
  );
};
