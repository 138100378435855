import { cn } from '@/core/ui/utils';

import { PromotionSectionsData } from '../queries/types';

import { PromotionTile } from './PromotionTile';
import { SectionHeading } from './SectionHeading';

export const PromotionsSection = ({
  section,
}: {
  section: PromotionSectionsData;
}) => {
  const { promotions, title } = section;

  return (
    <div className={cn('px-4')}>
      <SectionHeading>{title}</SectionHeading>
      <ul className={cn('flex', 'gap-4')}>
        {promotions?.map(
          ({ key, webImage, title: promotionTitle, backgroundColor }) => (
            <PromotionTile
              key={key}
              promotionKey={key}
              mobileImageUrl={webImage.mobileImageUrl}
              title={promotionTitle}
              backgroundColor={backgroundColor}
            />
          )
        )}
      </ul>
    </div>
  );
};
