import { useMemo } from 'react';

import { MOBILE_SLIDE_SPACING } from '@/core/constants/sliders';
import { useSlider } from '@/core/hooks/useSlider';
import { BannersArrows } from '@/core/ui/components/BannersArrows/BannersArrows';
import { BannersDots } from '@/core/ui/components/BannersDots/BannersDots';
import { FC } from '@/types/common';

import { Banner } from '../types/types';

import { BannersCarouselSlide } from './BannersCarouselSlide';

export const BannersCarousel: FC<{
  banners: Banner[];
  naturalSlideWidth: number;
  naturalSlideHeight: number;
}> = ({ banners, naturalSlideHeight, naturalSlideWidth }) => {
  const durations = useMemo(
    () => banners?.map(({ replaceIn }) => replaceIn),
    [banners]
  );

  const {
    sliderRef,
    showControls,
    handleNext,
    handlePrev,
    handleMouseEnter,
    handleMouseLeave,
    currentSlide,
    handleDotClick,
    dotsLength,
  } = useSlider({
    breakpoints: {
      '(min-width: 768px)': {
        slides: { spacing: 0, perView: 1 },
      },
    },
    slides: {
      perView: 1,
      spacing: MOBILE_SLIDE_SPACING,
    },
    loop: banners.length > 1,
    initial: 0,
    durations,
  });

  return (
    <>
      <div className="relative">
        <div className="keen-slider" ref={sliderRef}>
          {banners.map((restProps, index) =>
            restProps.src ? (
              <BannersCarouselSlide
                key={restProps.id}
                naturalSlideHeight={naturalSlideHeight}
                naturalSlideWidth={naturalSlideWidth}
                index={index}
                {...restProps}
                title=""
              />
            ) : null
          )}
        </div>
        {showControls && (
          <BannersArrows
            onPrev={handlePrev}
            onNext={handleNext}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        )}
      </div>
      {showControls && dotsLength && (
        <BannersDots
          currentSlide={currentSlide}
          totalSlides={dotsLength}
          onClick={handleDotClick}
        />
      )}
    </>
  );
};
