import { useMemo } from 'react';

import { CategoryLandingBanners } from '@/modules/categoryLanding/queries/types';
import { HomepageBanners as HomeBanners } from '@/modules/home/queries/types';
import { transformSanityImageUrlToImagePlaceholder } from '@lib/sanity/transform-sanity-image-url-to-image-placeholder';
import { transformSanityImageUrlToImageUrl } from '@lib/sanity/transform-sanity-image-url-to-image-url';

import {
  NATURAL_DESKTOP_BANNER_HEIGHT,
  NATURAL_DESKTOP_BANNER_WIDTH,
  NATURAL_MOBILE_BANNER_HEIGHT,
  NATURAL_MOBILE_BANNER_WIDTH,
} from '../constants/bannerDimensions';
import { mapBanners } from '../services/mapBanners';

const defaultImgProps = {
  imageWidth: NATURAL_MOBILE_BANNER_WIDTH,
  imageHeight: NATURAL_MOBILE_BANNER_HEIGHT,
  q: 85,
};

type Props = {
  banners: CategoryLandingBanners | HomeBanners;
};

export const useBanners = ({ banners }: Props) => {
  const mappedBanners = useMemo(() => {
    if (banners) {
      return mapBanners(banners);
    }
    return [];
  }, [banners]);

  const desktopBanners = useMemo(
    () =>
      mappedBanners.map((banner) => ({
        id: banner.id,
        type: banner.__typename,
        replaceIn: 'replaceIn' in banner ? banner.replaceIn : null,
        src: transformSanityImageUrlToImageUrl({
          imageWidth: NATURAL_DESKTOP_BANNER_WIDTH,
          imageHeight: NATURAL_DESKTOP_BANNER_HEIGHT,
          dpr: 1,
          url: banner.desktopImageUrl,
        }),
        title: banner.title ?? null,
        link: 'link' in banner && banner.link ? banner.link : null,
        placeholder: transformSanityImageUrlToImagePlaceholder({
          imageWidth: NATURAL_DESKTOP_BANNER_WIDTH,
          imageHeight: NATURAL_DESKTOP_BANNER_HEIGHT,
          url: banner.desktopImageUrl,
        }),
        srcSetObj: {
          '2x': transformSanityImageUrlToImageUrl({
            imageWidth: NATURAL_DESKTOP_BANNER_WIDTH,
            imageHeight: NATURAL_DESKTOP_BANNER_HEIGHT,
            dpr: 2,
            url: banner.desktopImageUrl,
          }),
          '3x': transformSanityImageUrlToImageUrl({
            imageWidth: NATURAL_DESKTOP_BANNER_WIDTH,
            imageHeight: NATURAL_DESKTOP_BANNER_HEIGHT,
            dpr: 3,
            url: banner.desktopImageUrl,
          }),
        },
      })),
    [mappedBanners]
  );

  const mobileBanners = useMemo(
    () =>
      mappedBanners.map((banner) => ({
        id: banner.id,
        type: banner.__typename,
        replaceIn: 'replaceIn' in banner ? banner.replaceIn : null,
        src: transformSanityImageUrlToImageUrl({
          ...defaultImgProps,
          dpr: 1,
          url: banner.mobileImageUrl,
        }),
        title: banner.title ?? null,
        link: 'link' in banner && banner.link ? banner.link : null,
        placeholder: transformSanityImageUrlToImagePlaceholder({
          ...defaultImgProps,
          url: banner.mobileImageUrl,
        }),
        srcSetObj: {
          '2x': transformSanityImageUrlToImageUrl({
            ...defaultImgProps,
            dpr: 2,
            url: banner.mobileImageUrl,
          }),
          '3x': transformSanityImageUrlToImageUrl({
            ...defaultImgProps,
            dpr: 3,
            url: banner.mobileImageUrl,
          }),
        },
      })),
    [mappedBanners]
  );

  return {
    desktopBanners,
    mobileBanners,
    desktopBannerHeight: NATURAL_DESKTOP_BANNER_HEIGHT,
    desktopBannerWidth: NATURAL_DESKTOP_BANNER_WIDTH,
    mobileBannerHeight: NATURAL_MOBILE_BANNER_HEIGHT,
    mobileBannerWidth: NATURAL_MOBILE_BANNER_WIDTH,
  };
};
