import { FC } from '@/types/common';
import { Img, ImgProps } from '@lib/img/components/Img';

import { Skeleton } from '../../Skeleton/Skeleton';

export const BannersCarouselSlideImage: FC<BannersCarouselSlideImageProps> = ({
  title,
  src,
  srcSetObj,
  placeholderSrc,
  naturalSlideHeight,
  naturalSlideWidth,
  isVisible,
}) => (
  <Img
    placeholder={
      <Skeleton width={naturalSlideWidth} height={naturalSlideHeight} />
    }
    width={naturalSlideWidth}
    height={naturalSlideHeight}
    alt={title}
    src={isVisible ? src : placeholderSrc}
    srcSetObj={srcSetObj}
    imageFit="fill"
    imagePosition="center"
    loading="eager"
    fetchPriority="high"
  />
);

export type BannersCarouselSlideImageProps = {
  title: string;
  src: string;
  srcSetObj: ImgProps['srcSetObj'];
  naturalSlideWidth: number;
  naturalSlideHeight: number;
  placeholderSrc: string;
  isVisible: boolean;
};
