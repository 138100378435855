import {
  OverlayScrollbarsComponent,
  OverlayScrollbarsComponentProps,
} from 'overlayscrollbars-react';
import { FC } from 'react';

export const Scrollbars: FC<ScrollbarsProps> = ({
  children,
  ...providedProps
}) => {
  const {
    options: providedOptions,
    style: providedStyle,
    ...appliedProps
  } = providedProps;

  const { scrollbars, ...appliedOptions } = providedOptions || {};

  const style = {
    ...providedStyle,
    paddingBottom: providedStyle?.paddingBottom ?? 0,
    marginBottom: providedStyle?.marginBottom ?? 0,
  } as const;

  return (
    <OverlayScrollbarsComponent
      {...appliedProps}
      style={style}
      options={{
        ...appliedOptions,
        scrollbars: {
          ...scrollbars,
          clickScroll:
            typeof scrollbars?.clickScroll === 'undefined'
              ? true
              : scrollbars?.clickScroll,
          visibility: scrollbars?.visibility ?? 'auto',
          // Don't use `autoHide` option. It breaks the container's behavior
          // on iOS. When autoHide is enabled the user on iOS has to double-tap
          // interactive elements of the scrollable content to perform any
          // action.
          autoHide: 'never',
        },
      }}
    >
      {children}
    </OverlayScrollbarsComponent>
  );
};

type ScrollbarsProps = Omit<OverlayScrollbarsComponentProps, 'options'> & {
  options?: OverlayScrollbarsComponentProps['options'];
};
