import { SanityTitleFormatter } from '@/core/ui/components/SanityTitleFormatter/SanityTitleFormatter';
import { cn } from '@/core/ui/utils';

/**
 * It takes string as children and parses it so we can easily mix different
 * fonts for the headings.
 *
 * Example:
 *
 * ```tsx
 * <SectionHeading>{t("Your <Italic>last order</Italic>")}</SectionHeading>
 * ```
 *
 * Currently is does support only `<Italic>` tag.
 */
export const SectionHeading = ({
  children,
  size = '3xl',
}: {
  children: string;
  size?: '3xl' | '5xl';
}) => (
  <h2
    className={cn(
      size === '3xl'
        ? ['text-2xl', 'md:text-3xl']
        : ['text-3xl', 'md:text-3xl', 'xl:text-5xl'],
      'mb-6'
    )}
  >
    <SanityTitleFormatter>{children}</SanityTitleFormatter>
  </h2>
);
