import { ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';

import { cn } from '@/core/ui/utils';
import { FCC } from '@/types/common';

export const UvpSectionTile: FCC<{
  title: string;
  description: ReactNode;
}> = ({ title, children, description }) => {
  const [setTileRef, isTileInViewport] = useInView({
    delay: 50,
    threshold: 0,
    rootMargin: '0px',
    fallbackInView: true,
    triggerOnce: true,
  });

  return (
    <div
      ref={setTileRef}
      className={cn(
        'bg-gray-000',
        'min-w-[280px]',
        'h-[408px]',
        'shadow-lg',
        'rounded-[32px]',
        'flex',
        'flex-col',
        'items-center',
        'px-8',
        'py-10',
        'gap-y-6'
      )}
    >
      <h4 className={cn('text-lg', 'sm:text-2xl', 'mt-2', 'font-medium')}>
        {title}
      </h4>
      <div className={cn('pb-4')}>{isTileInViewport ? children : null}</div>
      <p className={cn('text-sm', 'text-gray-700', 'text-center')}>
        {description}
      </p>
    </div>
  );
};
