import { ReactNode } from 'react';

import { ProductTile } from '@/core/ui/components/ProductTile/ProductTile';
import { cn } from '@/core/ui/utils';
import { Product } from '@/modules/product/queries/types';

const slideClassNames = ['keen-slider__slide', 'min-w-full'];

type ProductsCarouselSliderProps = {
  products: readonly Product[];
  customSlides?: ReactNode[];
  isProductTitleNotClickable?: boolean;
};

export const ProductsCarouselSlider = ({
  products,
  customSlides,
  isProductTitleNotClickable,
}: ProductsCarouselSliderProps) => (
  <>
    {products.map((product, i) => (
      <div key={i} className={cn(slideClassNames)}>
        <ProductTile
          product={product}
          isNotClickable={isProductTitleNotClickable}
        />
      </div>
    ))}
    {customSlides?.map((slide, i) => (
      <div key={i} className={cn(slideClassNames)}>
        {slide}
      </div>
    ))}
  </>
);
