import { useTranslation } from 'next-i18next';
import { MouseEvent } from 'react';

import { cn } from '@/core/ui/utils';

import { BannerArrow } from './components/BannerArrow/BannerArrow';

type BannersArrowsProps = {
  onPrev: (e: MouseEvent<HTMLButtonElement>) => void;
  onNext: (e: MouseEvent<HTMLButtonElement>) => void;
  onMouseLeave?: () => void;
  onMouseEnter?: () => void;
  isPrevDisabled?: boolean;
  isNextDisabled?: boolean;
  className?: string;
};

export const BannersArrows = ({
  onPrev,
  onNext,
  onMouseEnter,
  onMouseLeave,
  isPrevDisabled,
  isNextDisabled,
  className,
}: BannersArrowsProps) => {
  const { t } = useTranslation('common');

  return (
    <div className={cn('hidden sm:block', className)}>
      <BannerArrow
        type="left"
        onClick={onPrev}
        title={t('Previous slide')}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
        isDisabled={isPrevDisabled}
      />
      <BannerArrow
        type="right"
        onClick={onNext}
        title={t('Next slide')}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
        isDisabled={isNextDisabled}
      />
    </div>
  );
};
