import dynamic from 'next/dynamic';
import { Trans, useTranslation } from 'next-i18next';
import { useInView } from 'react-intersection-observer';

import { cn } from '@/core/ui/utils';
import { Scrollbars } from '@lib/theme/components/Scrollbars';

import { SectionHeading } from './SectionHeading';
import { UvpSectionTile } from './UvpSectionTile';

const Cart = dynamic(async () => import('../images/home-page-cart.svg'), {
  ssr: false,
});

const CreditCard = dynamic(
  async () => import('../images/home-page-credit-card.svg'),
  { ssr: false }
);

const Cup = dynamic(async () => import('../images/home-page-cup.svg'), {
  ssr: false,
});

const Watch = dynamic(async () => import('../images/home-page-watch.svg'), {
  ssr: false,
});

export const UvpSection = () => {
  const { t } = useTranslation('home');
  const [setSectionRef, isImgInViewport] = useInView();

  return (
    <div className={cn('relative')} ref={setSectionRef}>
      {isImgInViewport && (
        <>
          <Background />
          <Scrollbars style={{ marginBottom: 0 }}>
            <div
              className={cn(
                'max-w-7xl',
                'm-auto',
                'px-4',
                'box-content',
                'mb-5'
              )}
            >
              <div className={cn('z-20')}>
                <div
                  className={cn(
                    'text-primary-500',
                    'pt-14',
                    'pb-7',
                    'block',
                    'font-medium'
                  )}
                >
                  <SectionHeading size="5xl">
                    {t('Discover <Italic>delio supermarket</Italic>')}
                  </SectionHeading>
                </div>
                <div className={cn('flex', 'gap-x-8')}>
                  <div>
                    <UvpSectionTile
                      title={t('Pick a time')}
                      description={
                        <span className="max-w-[216px]">
                          <Trans
                            ns="home"
                            i18nKey="Shopping even in 2 hours?<NewLine/>It's not magic, it's delio!<NewLine/>Choose the best time between<NewLine/>7am and 11pm, including<NewLine/>Sundays!"
                            components={{ NewLine: <br /> }}
                          />
                        </span>
                      }
                    >
                      <Watch className={iconClassNames} aria-hidden />
                    </UvpSectionTile>
                  </div>

                  <UvpSectionTile
                    title={t('Shop Conveniently')}
                    description={t(
                      'Browse product categories or use the search engine directly - and easily add your desired items to the cart.'
                    )}
                  >
                    <Cart className={iconClassNames} aria-hidden />
                  </UvpSectionTile>
                  <UvpSectionTile
                    title={t('Pay as you like')}
                    description={t('Pay by card or use BLIK')}
                  >
                    <CreditCard className={iconClassNames} aria-hidden />
                  </UvpSectionTile>
                  <div className={cn('pr-4')}>
                    <UvpSectionTile
                      title={t('Relax')}
                      description={t(
                        '...and we will complete your order. Our suppliers will deliver exactly when it suits you!'
                      )}
                    >
                      <Cup className={iconClassNames} aria-hidden />
                    </UvpSectionTile>
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
        </>
      )}
    </div>
  );
};

const iconClassNames = cn('text-primary-500', 'w-[103px]', 'h-[133px]');

const Background = () => (
  <div className={cn('bg-[#F1E2D2]', 'w-full', 'absolute', 'h-[474px]')} />
);
