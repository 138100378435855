import { useMemo } from 'react';

import { useUserAddressCoordinates } from '@/core/hooks/useUserAddressCoordinates';

import { useAllHomeFeedQuery } from '../queries/AllHomeFeed.delio.generated';

export const useAllHomeFeed = () => {
  const {
    coordinates,
    error: coordinatesError,
    loading: coordinatesLoading,
  } = useUserAddressCoordinates();

  const allHomeFeedResult = useAllHomeFeedQuery({
    variables: {
      coordinates,
    },
    skip: coordinatesLoading,
    notifyOnNetworkStatusChange: true,
  });

  const { data } = allHomeFeedResult;
  const error = allHomeFeedResult.error ?? coordinatesError;
  const isLoading = allHomeFeedResult.loading || coordinatesLoading;
  return useMemo(
    () => ({
      data,
      isLoading,
      error: data ? undefined : error,
    }),
    [data, error, isLoading]
  );
};
