import { FC } from '@/types/common';

import { ProductsSectionData } from '../../queries/types';

import { ProductsSectionContent } from './ProductsSectionContent';

export const ProductsSection: FC<{
  section?: ProductsSectionData | null;
}> = ({ section }) => {
  if (!section) return null;

  return <ProductsSectionContent section={section} />;
};
