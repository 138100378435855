import parse, { DOMNode, domToReact } from 'html-react-parser';
import { ReactNode } from 'react';

import { cn } from '@/core/ui/utils';

export const SanityTitleFormatter = ({ children }: { children: string }) => (
  <>{generateDomFromString(children)}</>
);

const generateDomFromString = (text: string) =>
  parse(text, {
    replace: (dom) => {
      if (dom.type === 'tag' && 'children' in dom) {
        return <Italic>{domToReact(dom.children as DOMNode[])} </Italic>;
      }
      return dom;
    },
  });

const Italic = ({ children }: { children: ReactNode }) => (
  <span
    className={cn('italic', 'font-serif', 'font-medium')}
    data-testid="SectionHeading__italic-text"
  >
    {children}
  </span>
);
