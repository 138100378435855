import { cn } from '@/core/ui/utils';

export const HomePageSkeleton = () => (
  <div className={cn('max-w-7xl', 'm-auto', 'w-full')}>
    <div
      className={cn(
        'flex',
        'flex-col',
        'gap-4',
        'w-full',
        'min-w-max',
        'pt-4',
        'px-4',
        'xl:px-0'
      )}
    >
      {new Array(skeletonsAmount).fill(0).map((_, index) => (
        <HomePageSkeletonTile
          key={index}
          height={index % isEvenDivider === 0 ? 'h-[260px]' : 'h-[468px]'}
        />
      ))}
    </div>
  </div>
);

const HomePageSkeletonTile = ({ height }: { height: string }) => (
  <div
    className={cn(
      'bg-gray-200',
      'animate-pulse',
      'rounded-xl',
      'w-full',
      height
    )}
  />
);

const skeletonsAmount = 5;
const isEvenDivider = 2;
